import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './screens/Main';
import { Route, BrowserRouter, Routes, Outlet } from 'react-router-dom';
import Contratos from './screens/Contratos';
import { SplashScreen } from './screens/Splash';
import Clausulas from './screens/Clausulas';
import { sistema } from './model/Sistema';
import { login, logout } from './lib/auth';
import Usuario from './model/Usuario';
import Clausula from './screens/Clausula';
import Templates from './screens/Templates';
import Template from './screens/Template';
import WYSIWYGEditor from './screens/WYSIWYGEditor';
import EditorTest from './screens/EditorTest';
import Contrato from './screens/Contrato';
import APIManager from './lib/apiManager';
import Clientes from './screens/Clientes';
import ClientesExcluidos from './screens/ClientesExcluidos';
import Cliente from './screens/Cliente';
import ControlDeAsistencia from './screens/ControlDeAsistencia';
import moment from 'moment';
import 'moment/locale/es';
import FirmarContrato from './screens/FirmarContrato';
import Espacios from './screens/Espacios';
import Espacio from './screens/Espacio';

// i18n Tolgee
/* import { Tolgee, DevTools, TolgeeProvider, FormatSimple, BackendFetch } from "@tolgee/react";

const tolgee = Tolgee()
.use(DevTools())
.use(FormatSimple())
.use(BackendFetch({ prefix: 'https://cdn.tolg.ee/56d2b03e16d84f65c6e99329d9c4d698' }))
.init({
  language: 'es-AR',
  availableLanguages: ['es-AR', 'en'],
  defaultLanguage: 'es-AR',

  // for development
  apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
  apiKey: process.env.REACT_APP_TOLGEE_API_KEY,

});
 */

function App({version}) {
  const [isLoading, setIsLoading] = useState(true);
  const [usuario, setUsuario] = useState(null);
  const [espacios, setEspacios] = useState([]);
  const [espacio, setEspacio] = useState(null);
  const [menu, setMenu] = useState([]);

  const [botonera, setBotonera] = useState([]);

  moment.locale('es');

  const setLoginData = (usuarioData) => {
    //sistema.usuario = new Usuario(usuarioData);
    sistema.usuario = new Usuario(usuarioData);
    sistema.space_id = usuarioData.espacio_id;
    sistema.oficinas = usuarioData.oficinas;
    setEspacios(sistema.espacios);
    setEspacio(sistema.espacio);
    setMenu(sistema.menu);
    // APIManager.getList('espacios', {}, (response) => {
    //   if (response.success) {
    //     setEspacios(response.data.listado);
    //     sistema.espacios = response.data.listado;
    //     sistema.espacio = sistema.espacios.find(item => item.id === sistema.space_id);
    //     setEspacio(sistema.espacio);
    //   }
    // });
    // APIManager.sendRequest('post', 'sistema', 'getMenu', {}, (response) => {
    //   if (response.success) {
    //     sistema.menu = response.data;
    //   }
    // })
    setUsuario(sistema.usuario);
    window.sistema = sistema;
  };

  const doLogin = () => {
    return login(setLoginData);
  };

  let primera = true;

  useEffect(() => {

    if (primera && !usuario) {
      primera = false;
      doLogin();
    }

    if (usuario) {
      setIsLoading(false);
    }

  }, [usuario, isLoading]);

  const doLogout = () => {
    setIsLoading(true);
    return logout(setUsuario);
  };

  const changeEspacio = (espacioElegido) => {
    // const espacioElegido = espacios.find(item => item.id === espacioID);
    setEspacio(espacioElegido);
    sistema.espacio = espacioElegido;
    sistema.saveActual();
  }

  return (
  //   <TolgeeProvider
  //   tolgee={tolgee}
  //   // fallback="Loading..." // loading fallback
  // >
    isLoading
      ? <SplashScreen version = { version } />
      : <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main doLogout={doLogout} menu={menu} botonera={botonera} espacios={espacios} espacio={espacio} setEspacio={changeEspacio} />}>
            <Route path="contratos" element={<div><Outlet /></div>}>
              <Route index element={<Contratos setBotonera={setBotonera} espacios={espacios} />} />
              <Route path=":contratoId" element={<Contrato setBotonera={setBotonera} espacios={espacios} />} />
            </Route>
            <Route path="firmarContrato" >
              <Route path=":contratoId" element={<FirmarContrato setBotonera={setBotonera} />} />
            </Route>
            <Route path="asistencia" element={<div><Outlet /></div>}>
              <Route index element={<ControlDeAsistencia setBotonera={setBotonera} espacio={espacio} />} />
            </Route>
            <Route path="clientes" element={<div><Outlet /></div>}>
              <Route index element={<Clientes setBotonera={setBotonera} />} />
              <Route path=":clienteId" element={<Cliente setBotonera={setBotonera} espacios={espacios} />} />
            </Route>
            <Route path="clientesExcluidos" element={<ClientesExcluidos setBotonera={setBotonera}/>}></Route>
            <Route path="clausulas" element={<div><Outlet /></div>}>
              <Route index element={<Clausulas />} />
              <Route path=":clausulaId" element={<Clausula />} />
            </Route>
            <Route path="templates" element={<div><Outlet /></div>}>
              <Route index element={<Templates setBotonera={setBotonera} />} />
              <Route path=":templateId" element={<Template setBotonera={setBotonera} />} />
            </Route>
            <Route path="espacios" element={<div><Outlet /></div>}>
              <Route index element={<Espacios setBotonera={setBotonera} />} />
              <Route path=":espacioId" element={<Espacio setBotonera={setBotonera} />} />
            </Route>
            <Route path="editorTest" element={<EditorTest/>} />
          </Route>

        </Routes>
      </BrowserRouter>
  // </TolgeeProvider>
  );
}

export default App;
